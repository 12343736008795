* {
  font-size: 18px;
  
}

table {
  padding: 10px;
}

span {
  padding: 14px;
}

.green {
  background-color: #08469f;
  color: white;
}

.fadeIn {
  opacity: 1;
  transition: opacity 0.3s;
}

.fadeOut {
  opacity: 0.7;
  transition: opacity 0.3s;
}

.red {
  background-color: #f3c53f;
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: 8px;
  align-items: center;
  gap: 5px;
}

